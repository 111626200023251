import React from 'react'

import Layout from '../components/layout'

const TeamPage = () => (
  <Layout bgClass="team">

  <div className="textPage">
  <h2 className="major">The Team</h2>
  <div className="textPageDetails">
    <p>
    Nakatomi Print Labs began as an extension of <a href="http://www.nakatomiinc.com/">NakatomiInc.com</a>, the artist collective based out of Austin, TX. What started in 2009 as a simple garage screenprinting studio has grown into a full-fledged printshop, serving clients all over the world and printing everything from fine art prints to worldwide gigposters. Nakatomi Print Labs has the distinction of being run, staffed, and utilized by artists and is committed to delivering the best quality screen printed poster possible.
    </p>
  </div>
  </div>
  </Layout>
)

export default TeamPage
